



















import { defineComponent, getCurrentInstance, Ref } from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'

import PageView from '@/components/templates/h/jyuyou-yosoku/causal-weight-conf/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import {
  causalWeightConfExtractCondition,
  causalWeightConfOutputCondition,
  ColumnLabel,
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPCausalWeightConfsCountDocument,
} from '@/types/generated/graphql'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_CAUSAL_COND_ITEM,
  OUTPUT_COND_STORE,
  SELECT_MAX_COUNT,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
} from '@/utils/constant'
import { EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { addDayYMD } from '@/utils/mixin'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  extractTarget: OUTPUT_EXTRACT_TARGET_DEFAULT, // 出力条件（抽出対象）はデフォルト値を設定できるようにしていないため、定数を用意した
  storeOutput: [
    OUTPUT_COND_STORE.all,
    OUTPUT_COND_STORE.area,
    OUTPUT_COND_STORE.tanten,
  ],
  itemOutput: [
    OUTPUT_CAUSAL_COND_ITEM.all,
    OUTPUT_CAUSAL_COND_ITEM.shobunrui,
    OUTPUT_CAUSAL_COND_ITEM.class,
    OUTPUT_CAUSAL_COND_ITEM.category,
    OUTPUT_CAUSAL_COND_ITEM.tanpin,
  ],
  delOutput: [],
}
const DEFAULT_EXTRACT_CONST = {
  validDate: [addDayYMD(1), '2999-12-31'],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      defaultDateValue: DEFAULT_EXTRACT_CONST.validDate,
      resourceType: ResourceType.UiPCausalWeightConf,
      templateFile: TemplateFile.UiPCausalWeightConf_01,
      extractCondition: {
        validData: DEFAULT_EXTRACT_CONST.validDate,
        storeShelve: [],
        store: [],
        shobunrui: [],
        class: [],
        category: [],
        tanpin: [],
        causal: [],
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      requestCondition: {},
      outputUseList: [
        {
          outputCondType: 'Store',
          displayItems: [
            OUTPUT_COND_STORE.all,
            OUTPUT_COND_STORE.area,
            OUTPUT_COND_STORE.tanten,
          ],
          defaultSelected: DEFAULT_OUTPUT_CONST.storeOutput,
          disabledItems: [],
        },
        {
          outputCondType: 'CausalItem',
          displayItems: [
            OUTPUT_CAUSAL_COND_ITEM.all,
            OUTPUT_CAUSAL_COND_ITEM.shobunrui,
            OUTPUT_CAUSAL_COND_ITEM.class,
            OUTPUT_CAUSAL_COND_ITEM.category,
            OUTPUT_CAUSAL_COND_ITEM.tanpin,
          ],
          defaultSelected: DEFAULT_OUTPUT_CONST.itemOutput,
          disabledItems: [],
        },
        {
          outputCondType: 'Delete',
          displayItems: [OUTPUT_COND_DELETE.includeDelete],
          defaultSelected: DEFAULT_OUTPUT_CONST.delOutput,
          disabledItems: [],
        },
      ],
      totalCount: null,
      exportSpinner: false,
    }
  },
  methods: {
    changeExtractCondition(cond: causalWeightConfExtractCondition) {
      console.log('changeExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: causalWeightConfOutputCondition) {
      console.log('changeOutputCondition', cond)
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.exportSpinner = true
      this.totalCount = null
      if (this.checkRequired()) {
        try {
          this.requestCondition = this._createWhere()
          const result = await this.$apollo.query({
            query: VUiPCausalWeightConfsCountDocument,
            variables: { where: this.requestCondition },
          })
          const data = result.data.vUiPCausalWeightConfs.totalCount
          this.totalCount = data.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },
    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      // 有効日
      if (
        !this.extractCondition.validData[0] ||
        !this.extractCondition.validData[1] ||
        (this.extractCondition.storeShelve.length == 0 &&
          this.extractCondition.store.length == 0 &&
          this.extractCondition.shobunrui.length == 0 &&
          this.extractCondition.class.length == 0 &&
          this.extractCondition.category.length == 0 &&
          this.extractCondition.tanpin.length == 0 &&
          this.extractCondition.causal.length == 0)
      ) {
        errMsg.push({
          message: this.$i18n.t('error.requiredItemNotInput'),
        })
      }
      // 選択数上限チェック
      if (this.extractCondition.storeShelveCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.areaStore'),
            selected: this.extractCondition.storeShelveCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.storeCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.store'),
            selected: this.extractCondition.storeCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.shobunruiCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.shobunrui'),
            selected: this.extractCondition.shobunruiCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.classCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.class'),
            selected: this.extractCondition.classCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.categoryCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.class'),
            selected: this.extractCondition.categoryCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }   
      if (this.extractCondition.tanpinCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.tanpin'),
            selected: this.extractCondition.tanpinCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.causalCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.causal'),
            selected: this.extractCondition.causalCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/
    /**
     * エクスポート処理
     */
    async requestExport() {
      if (this.checkRequired(true)) {
        // this._create_query_string()
        await this.helper.export({
          query: this._create_query_string(),
          jmespathQuery:
            'data.vUiPCausalWeightConfs.edges[].node | map(&merge(`{"__update": null}`, @), @)',
          templateFile: this.templateFile,
          filenameExport: EXPORT_FILENAME.causal_weight_conf,
          columnLabelMapping: this._create_column_label_mapping(),
        })
      }
    },
    _create_query_string() {
      const where = this._createWhere()
      const rowQuery = `
query {
  vUiPCausalWeightConfs(
    where: ${this.helper.generateQueryWhereString(where)}
    orderBy: ${this._createOrderByString()}
  ) {
    edges {
      node {
        inactiveFlg
        storeApplyLevel
        storeApplyScope
        vUiMStoreApplyLevel {
          storeApplyScopeName
        }
        causalItemApplyLevel
        causalItemApplyScope
        causalItemApplyScopeName
        category2Cd
        category2Name
        category3Cd
        category3Name
        startDate
        endDate
        causalCdName
        causalType
        causalTypeName
        adjustType
        adjustTypeName
        rsvParam1
        rsvParam2
        rsvParam3
        rsvParam4
        rsvParam5
        rsvParam6
        rsvParam7
        rsvParam8
        rsvParam9
        rsvParam10
        rsvParam11
        rsvParam12
        rsvParam13
        rsvParam14
        rsvParam15
        rsvParam16
        rsvParam17
        rsvParam18
        rsvParam19
        rsvParam20
        rsvParam21
        rsvParam22
        rsvParam23
        rsvParam24
        rsvParam25
        rsvParam26
        rsvParam27
        rsvParam28
        rsvParam29
        rsvParam30
        rsvParam31
        configuredFlg
        lastModifiedDatetime
        lastModifiedUserName
      }
    }
  }
}
`
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 抽出条件
        { field: ['startDate'], op: WhereOp.Le, value: [''] },
        { field: ['endDateNvl'], op: WhereOp.Ge, value: [''] },

        // 店舗コード、棚パターン、小分類、商品コードの抽出条件
        {
          field: ['vUiMStoreApplyLevel/validStores/storeCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiMStoreApplyLevel/vUiPAreas/areaCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category1Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category2Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category3Cd'],
          op: WhereOp.In,
          value: [''],
        }, 
        {
          field: ['saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },
        { field: ['causalCd'], op: WhereOp.In, value: [''] },

        // 出力条件
        { field: ['storeApplyLevel'], op: WhereOp.In, value: [''] },
        { field: ['causalItemApplyLevel'], op: WhereOp.In, value: [''] },
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
        // 設定行フラグ　1固定
        { field: ['configuredFlg'], op: WhereOp.In, value: ['1'] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      const validData = this.extractCondition.validData
      setWhereValue(where, 'startDate', [validData[1]])
      setWhereValue(where, 'endDateNvl', [validData[0]])
      setWhereValue(
        where,
        'vUiMStoreApplyLevel/validStores/storeCd',
        this.extractCondition.store
      )
      setWhereValue(
        where,
        'vUiMStoreApplyLevel/vUiPAreas/areaCd',
        this.extractCondition.storeShelve
      )
      // 小分類
      setWhereValue(
        where,
        'category1Cd',
        this.extractCondition.shobunrui
      )
      // クラス
      setWhereValue(where, 'category2Cd', this.extractCondition.class)
      // カテゴリ
      setWhereValue(where, 'category3Cd', this.extractCondition.category)
      // 単品
      setWhereValue(
        where,
        'saleItemCd',
        this.extractCondition.tanpin
      )
      setWhereValue(where, 'causalCd', this.extractCondition.causal)

      setWhereValue(where, 'storeApplyLevel', this.outputCondition.storeOutput)
      setWhereValue(where, 'causalItemApplyLevel', this.outputCondition.itemOutput)
      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])
      console.log({ where })
      return where
    },
    _createOrderByString() {
      const orderBy: OrderBy[] = [
        {
          field: 'storeApplyLevel',
          direction: OrderByDirection.Desc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'storeApplyScope',
          direction: OrderByDirection.Desc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'causalItemApplyLevel',
          direction: OrderByDirection.Desc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'causalItemApplyScope',
          direction: OrderByDirection.Desc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'category2Cd',
          direction: OrderByDirection.Desc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'category3Cd',
          direction: OrderByDirection.Desc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'startDate',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'endDate',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
      console.log({ orderBy })
      return this.helper.generateQueryOrderByString(orderBy)
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'storeApplyLevel',
          to: '',
        },
        {
          from: 'storeApplyLevelName',
          to: '',
        },
        {
          from: 'storeApplyScope',
          to: '',
        },
        {
          from: 'vUiMStoreApplyLevel.storeApplyScopeName',
          to: '',
        },
        {
          from: 'causalItemApplyLevel',
          to: '',
        },
        {
          from: 'causalItemApplyLevelName',
          to: '',
        },
        {
          from: 'causalItemApplyScope',
          to: '',
        },
        {
          from: 'causalItemApplyScopeName',
          to: '',
        },
        {
          from: 'category2Cd',
          to: '',
        },
        {
          from: 'category2Name',
          to: '',
        },
        {
          from: 'category3Cd',
          to: '',
        },
        {
          from: 'category3Name',
          to: '',
        },
        {
          from: 'startDate',
          to: '',
        },
        {
          from: 'endDate',
          to: '',
        },
        {
          from: 'causalCd',
          to: '',
        },
        {
          from: 'causalCdName',
          to: '',
        },
        {
          from: 'causalType',
          to: '',
        },
        {
          from: 'causalTypeName',
          to: '',
        },
        {
          from: 'adjustType',
          to: '',
        },
        {
          from: 'adjustTypeName',
          to: '',
        },
        {
          from: 'rsvParam1',
          to: '',
        },
        {
          from: 'rsvParam2',
          to: '',
        },
        {
          from: 'rsvParam3',
          to: '',
        },
        {
          from: 'rsvParam4',
          to: '',
        },
        {
          from: 'rsvParam5',
          to: '',
        },
        {
          from: 'rsvParam6',
          to: '',
        },
        {
          from: 'rsvParam7',
          to: '',
        },
        {
          from: 'rsvParam8',
          to: '',
        },
        {
          from: 'rsvParam9',
          to: '',
        },
        {
          from: 'rsvParam10',
          to: '',
        },
        {
          from: 'rsvParam11',
          to: '',
        },
        {
          from: 'rsvParam12',
          to: '',
        },
        {
          from: 'rsvParam13',
          to: '',
        },
        {
          from: 'rsvParam14',
          to: '',
        },
        {
          from: 'rsvParam15',
          to: '',
        },
        {
          from: 'rsvParam16',
          to: '',
        },
        {
          from: 'rsvParam17',
          to: '',
        },
        {
          from: 'rsvParam18',
          to: '',
        },
        {
          from: 'rsvParam19',
          to: '',
        },
        {
          from: 'rsvParam20',
          to: '',
        },
        {
          from: 'rsvParam21',
          to: '',
        },
        {
          from: 'rsvParam22',
          to: '',
        },
        {
          from: 'rsvParam23',
          to: '',
        },
        {
          from: 'rsvParam24',
          to: '',
        },
        {
          from: 'rsvParam25',
          to: '',
        },
        {
          from: 'rsvParam26',
          to: '',
        },
        {
          from: 'rsvParam27',
          to: '',
        },
        {
          from: 'rsvParam28',
          to: '',
        },
        {
          from: 'rsvParam29',
          to: '',
        },
        {
          from: 'rsvParam30',
          to: '',
        },
        {
          from: 'rsvParam31',
          to: '',
        },
        {
          from: 'configuredFlg',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
